import { hasAuthorizations as useUserHasAuthorization } from '@/react/hooks/useUser';
import { currentUserStore } from '@/react/hooks/current-user-store';
import { getCurrentUser, getUserEnvironmentAuthorizations } from '../users/queries/useLoadCurrentUser';
import * as userHelpers from '../users/user.helpers';
angular.module('portainer.app').factory(
  'Authentication',

  /* @ngInject */
  function AuthenticationFactory($async, $state, Auth, OAuth, LocalStorage, StateManager, EndpointProvider, ThemeManager) {
    if (process.env.NODE_ENV === 'development') {
      window.login = loginAsync;
    }

    return {
      init: () => $async(initAsync),
      OAuthLogin: (code) => $async(OAuthLoginAsync, code),
      login: (username, password) => $async(loginAsync, username, password),
      logout: () => $async(logoutAsync),
      isAuthenticated,
      getUserDetails,
      isAdmin,
      isEdgeAdmin,
      isPureAdmin,
      hasAuthorizations,
      redirectIfUnauthorized,
    };

    function AJSUserState() {
      const { user, authorizations } = currentUserStore.getState();
      if (!user) {
        return {};
      }
      return {
        ID: user.Id,
        username: user.Username,
        role: user.Role,
        forceChangePassword: user.forceChangePassword,
        portainerAuthorizations: user.PortainerAuthorizations,
        endpointAuthorizations: authorizations,
      };
    }

    async function initAsync() {
      try {
        const userId = LocalStorage.getUserId();
        const user = AJSUserState();
        if (userId && user.ID === userId) {
          return true;
        }
        await loadUserData();
        return true;
      } catch (error) {
        return false;
      }
    }

    async function logoutAsync() {
      if (isAuthenticated()) {
        await Auth.logout().$promise;
      }

      sessionStorage.clear();
      StateManager.clean();
      EndpointProvider.clean();
      LocalStorage.cleanAuthData();
      LocalStorage.storeLoginStateUUID('');
      currentUserStore.getState().clear();
    }

    async function OAuthLoginAsync(code) {
      await OAuth.validate({ code: code }).$promise;
      await loadUserData();
    }

    async function loginAsync(username, password) {
      await Auth.login({ username: username, password: password }).$promise;
      await loadUserData();
    }

    function isAuthenticated() {
      return !!AJSUserState().ID;
    }

    function getUserDetails() {
      return AJSUserState();
    }

    async function loadUserData() {
      const userData = await getCurrentUser();

      if (!userHelpers.isPureAdmin(userData)) {
        const authData = await getUserEnvironmentAuthorizations(EndpointProvider.endpointID());
        currentUserStore.setState({ user: userData, authorizations: authData });
      } else {
        currentUserStore.setState({ user: userData });
      }

      // Initialize user theme base on UserTheme from database
      const userTheme = userData.ThemeSettings ? userData.ThemeSettings.color : 'auto';
      if (userTheme === 'auto' || !userTheme) {
        ThemeManager.autoTheme();
      } else {
        ThemeManager.setTheme(userTheme);
      }

      LocalStorage.storeUserId(userData.Id);
    }

    // To avoid creating divergence between CE and EE
    // isAdmin checks if the user is a portainer admin or edge admin
    function isEdgeAdmin(noEnvScope = false) {
      const environment = EndpointProvider.currentEndpoint();
      const user = AJSUserState();
      return userHelpers.isEdgeAdmin({ Role: user.role }, noEnvScope ? undefined : environment);
    }

    /**
     * @deprecated use Authentication.isAdmin instead
     */
    function isAdmin(noEnvScope = false) {
      return isEdgeAdmin(noEnvScope);
    }

    // To avoid creating divergence between CE and EE
    // isPureAdmin checks if the user is portainer admin only
    function isPureAdmin() {
      const user = AJSUserState();
      return userHelpers.isPureAdmin({ Role: user.role });
    }

    /**
     * Checks if the user has the required authorizations for this environment
     *
     * @param {Array<string>} authorizations
     * @returns boolean
     */
    function hasAuthorizations(authorizations) {
      const endpointId = EndpointProvider.endpointID();

      if (isEdgeAdmin()) {
        return true;
      }

      const user = AJSUserState();

      return useUserHasAuthorization(
        {
          Role: user.role,
          EndpointAuthorizations: user.endpointAuthorizations,
        },
        authorizations,
        endpointId
      );
    }

    function redirectIfUnauthorized(authorizations) {
      const authorized = hasAuthorizations(authorizations);
      if (!authorized) {
        $state.go('portainer.home');
      }
    }
  }
);
